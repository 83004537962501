import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { auth } from '../config/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

const AddLeadInvestorPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    photo: '',
    bio: '',
    credentials: [''],
    expertise: [''],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArrayChange = (index, field, value) => {
    setFormData(prevState => {
      const newArray = [...prevState[field]];
      newArray[index] = value;
      return { ...prevState, [field]: newArray };
    });
  };

  const addArrayField = (field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: [...prevState[field], '']
    }));
  };

  const removeArrayField = (field, index) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: prevState[field].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      const functions = getFunctions();
      const addLeadInvestor = httpsCallable(functions, 'addLeadInvestor');

      const result = await addLeadInvestor({
        ...formData,
        userId: user.uid,
      });

      console.log('Lead investor added successfully:', result.data);
      setSubmitMessage('Lead investor added successfully');
      setFormData({
        name: '',
        title: '',
        photo: '',
        bio: '',
        credentials: [''],
        expertise: [''],
      });
    } catch (error) {
      console.error('Error adding lead investor:', error);
      setSubmitMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputStyle = `w-full p-2 border rounded-md ${theme.inputBgColor} ${theme.inputBorderColor} text-black`;

  return (
    <div className={`min-h-screen py-4 px-6 ${theme.bgColor}`}>
      <Card className="max-w-2xl mx-auto p-6">
        <h1 className={`text-2xl font-bold mb-6 ${theme.textColor}`}>{t('addLeadInvestorPage.name')}</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.name')}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={inputStyle}
                required
              />
            </div>
            <div>
              <label htmlFor="title" className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.title')}</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className={inputStyle}
                required
              />
            </div>
            <div>
              <label htmlFor="photo" className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.photo')}</label>
              <input
                type="text"
                id="photo"
                name="photo"
                value={formData.photo}
                onChange={handleChange}
                className={inputStyle}
                placeholder={t('addLeadInvestorPage.photoPlaceholder')}
              />
            </div>
            <div>
              <label htmlFor="bio" className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.bio')}</label>
              <textarea
                id="bio"
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                className={inputStyle}
                rows="3"
                required
              ></textarea>
            </div>
            <div>
              <label className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.credentials')}</label>
              {formData.credentials.map((credential, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={credential}
                    onChange={(e) => handleArrayChange(index, 'credentials', e.target.value)}
                    className={`flex-grow ${inputStyle}`}
                    required
                  />
                  <Button
                    onClick={() => removeArrayField('credentials', index)}
                    className="ml-2"
                    variant="destructive"
                  >
                    -
                  </Button>
                </div>
              ))}
              <Button
                onClick={() => addArrayField('credentials')}
                className="mt-1"
                variant="outline"
              >
                +
              </Button>
            </div>
            <div>
              <label className={`block mb-1 font-medium ${theme.labelColor}`}>{t('addLeadInvestorPage.expertise')}</label>
              {formData.expertise.map((area, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={area}
                    onChange={(e) => handleArrayChange(index, 'expertise', e.target.value)}
                    className={`flex-grow ${inputStyle}`}
                    required
                  />
                  <Button
                    onClick={() => removeArrayField('expertise', index)}
                    className="ml-2"
                    variant="destructive"
                  >
                    -
                  </Button>
                </div>
              ))}
              <Button
                onClick={() => addArrayField('expertise')}
                className="mt-1"
                variant="outline"
              >
                +
              </Button>
            </div>
            <Button
              type="submit"
              className="w-full mt-4"
              style={{
                backgroundColor: theme.primaryColor,
                color: 'white',
                padding: '0.5rem 1rem',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? t('common.submitting') : t('addLeadInvestorPage.submit')}
            </Button>
          </form>
          {submitMessage && (
            <p className={`mt-4 text-center ${submitMessage.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {submitMessage}
            </p>
          )}
      </Card>
    </div>
  );
};

export default AddLeadInvestorPage;